// ⚠️ Deprecated file to be removed in MULA-1266

import AddAddress from './AddAddress.json'
import AddressBook from './AddressBook.json'
import BusinessProfileBackground from './BusinessProfileBackground.json'
import BusinessProfileBuyingEligibility from './BusinessProfileBuyingEligibility.json'
import BusinessProfileComplete from './BusinessProfileComplete.json'
import BusinessProfileFreightForwarder from './BusinessProfileFreightForwarder.json'
import BusinessProfilePage from './BusinessProfilePage.json'
import BusinessProfileRecycling from './BusinessProfileRecycling.json'
import BusinessProfileSettings from './BusinessProfileSettings.json'
import BusinessProfileSummaryPage from './BusinessProfileSummaryPage.json'
import BusinessProfileTaxIdPage from './BusinessProfileTaxIdPage.json'
import ChangePassword from './ChangePassword.json'
import ConfirmAddress from './ConfirmAddress.json'
import CreateProfilePage from './CreateProfilePage.json'
import ErrorModule from './Error.json'
import EsignTemplate from './EsignTemplate.json'
import File from './File.json'
import MarketplaceEligibility from './MarketplaceEligibility.json'
import MerchandiseTypes from './MerchandiseTypes.json'
import NotFoundPage from './NotFound.json'
import NotificationPreferencesPage from './NotificationPreferencesPage.json'
import OAuth2RedirectPage from './OAuth2RedirectPage.json'
import PaymentMethods from './PaymentMethods.json'
import PersonalSettings from './PersonalSettings.json'
import ResellerCertModal from './ResellerCertModal.json'
import Restrictions from './Restrictions.json'
import Settings from './Settings.json'
import SignIn from './SignIn.json'
import SignUp from './SignUp.json'
import SpecialDocs from './SpecialDocs.json'
import SubmitApplicationModal from './SubmitApplicationModal.json'
import TermsPopup from './TermsPopup.json'
import UserManagement from './UserManagement.json'
import VerifyAddress from './VerifyAddress.json'

export default {
  BusinessProfileSummaryPage,
  BusinessProfileBackground,
  BusinessProfileFreightForwarder,
  BusinessProfilePage,
  BusinessProfileTaxIdPage,
  BusinessProfileRecycling,
  ChangePassword,
  ConfirmAddress,
  CreateProfilePage,
  SpecialDocs,
  Restrictions,
  TermsPopup,
  SignUp,
  SignIn,
  VerifyAddress,
  Error: ErrorModule,
  BusinessProfileComplete,
  PaymentMethods,
  AddAddress,
  AddressBook,
  PersonalSettings,
  Settings,
  BusinessProfileSettings,
  BusinessProfileBuyingEligibility,
  File,
  EsignTemplate,
  SubmitApplicationModal,
  UserManagement,
  ResellerCertModal,
  NotificationPreferencesPage,
  NotFoundPage,
  OAuth2RedirectPage,
  MarketplaceEligibility,
  MerchandiseTypes,
}
