import makeReducer from '@helpers/makeReducer'

import types from './types'

type State = {
  initLoadComplete: boolean
  steps: Partial<Record<string, number>>
}
export const INITIAL_STATE: State = {
  initLoadComplete: false,
  steps: {
    // Keep it for debug
    // SignUp: 3,
    // BusinessBackground: 2
    // BusinessProfileRecycling: 1
    // BusinessProfileTaxIdPage: 5
    // AddAddress: 5,
  },
}

export default makeReducer<State>(INITIAL_STATE, {
  [types.SET_STEP]: (state, { step, moduleName }) => ({
    ...state,
    steps: {
      ...state.steps,
      [moduleName]: step,
    },
  }),
  [types.INIT_LOAD_FULFILLED]: (state) => ({
    ...state,
    initLoadComplete: true,
  }),
})
